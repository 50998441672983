<template>
<div>
  <router-view></router-view>
  <Loading v-show="loading"></Loading>
</div>
</template>

<script>
import Loading from "./components/loading/Modal.vue";

import { bus } from './main';
export default {
  name: 'App',
  components:{
    Loading
  },
  data() {
    return {
      loading:false,
    }
  },
  created() {
    bus.$on('loading', (data) => {
      this.loading = data;
    })
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
