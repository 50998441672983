import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import * as actions from "./actions";
import createPersistedState from 'vuex-persistedstate'

const state = {
  /** template */
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  /** api */
  // url: "http://pizarrito-back.test/api/v1", //local
  url: "https://intranetback.bazarpolicialpizarrito.com/api/v1", //nube
  /** auth */
  user: {
    id: null,
    name: null,
    email: null,
    roles: [],
    permissions: [],
    token:null
  }
};

const getters = {
  get__user(state){
    return state.user;
  },
  get__url(state) {
    return state.url;
  },
  get__user_id(state) {
    return state.user.id;
  },
  get__user_name(state) {
    return state.user.name;
  },
  get__token(state){
    return state.user.token
  },
  get__permissionxrol(state){
    return state.user.permissions
  }
};

const mutations = {
  /** template */
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  /** auth */
  mt_set_auth(state,payload) {
    state.user = payload;
  },
  mt_logout_auth(state){
    state.user= {
      id: null,
      name: null,
      email: null,
      roles: [],
      permissions: [],
      token:null
    };
  }
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState({
        reducer: (persistedState) => {
            const stateFilter = JSON.parse(JSON.stringify(persistedState));
            return stateFilter;
        }
    })
  ]
});
